import React, { createContext, useState } from 'react'
import axios from 'axios'

export const PhotoContext = createContext()

const PhotoContextProvider = props => {
  const [images, setImages] = useState([])
  const [loading, setLoading] = useState(true)
  const [filters, setFilters] = useState({ filterAge: [], filterSize: [] })
  const [searchTitle, setSearchTitle] = useState('')
  const runSearch = () => {
    axios
      .get(`/data.json`)
      .then(response => {
        setImages(response.data.photos.photo)
        setLoading(false)
      })
      .catch(error => {
        console.log(
          'Encountered an error with fetching and parsing data',
          error
        )
      })
  }
  
  const filteredImages = images
    .filter(p => (filters.filterAge.length < 1 || filters.filterAge.filter(a => p.ages.includes(a)).length > 0)
      && (filters.filterSize.length < 1 || filters.filterSize.filter(s => p.sizes.includes(s)).length > 0)
      && (searchTitle === '' || p.id.search(searchTitle.toLowerCase()) !== -1 || p.title.toLowerCase().search(searchTitle.toLowerCase()) !== -1)
    )
  
  return (
    <PhotoContext.Provider
      value={{ images: filteredImages, loading, runSearch, filters, setFilters, searchTitle, setSearchTitle }}>
      {props.children}
    </PhotoContext.Provider>
  )
}

export default PhotoContextProvider
