import React from 'react'
import Form from './Form'
import Navigation from './Navigation'

const Header = () => {
  return (
    <div>
      <h1>Портфейл</h1>
      <Form/>
      <Navigation/>
    </div>
  )
}

export default Header
