import React, { useContext } from 'react'
import { PhotoContext } from '../context/PhotoContext'
import FilterItem from './FilterItem'

const Navigation = () => {
  const { filters, setFilters } = useContext(PhotoContext)
  const handleFilterSizeClick = function (size) {
    const filterSize = (filters.filterSize.length < 1 || !filters.filterSize.includes(size))
      ? [...filters.filterSize, size]
      : filters.filterSize.filter((s) => s !== size)
    setFilters({ filterSize, filterAge: filters.filterAge })
  }
  const handleFilterAgeClick = function (age) {
    const filterAge = (filters.filterAge.length < 1 || !filters.filterAge.includes(age))
      ? [...filters.filterAge, age]
      : filters.filterAge.filter((a) => a !== age)
    setFilters({ filterAge, filterSize: filters.filterSize })
  }
  
  return (
    <nav className="main-nav">
      <ul>
        <FilterItem handleClick={handleFilterSizeClick} selectedValues={filters.filterSize} value={1}
                    title={'15см х 15см'}/>
        <FilterItem handleClick={handleFilterSizeClick} selectedValues={filters.filterSize} value={2}
                    title={'20см х 20см'}/>
      </ul>
      <ul>
        <FilterItem handleClick={handleFilterAgeClick} selectedValues={filters.filterAge} value={1}
                    title={'6 мес. - до 1 г.'}/>
        <FilterItem handleClick={handleFilterAgeClick} selectedValues={filters.filterAge} value={2}
                    title={'от 1 г. - до 3 г.'}/>
        <FilterItem handleClick={handleFilterAgeClick} selectedValues={filters.filterAge} value={3}
                    title={'от 3 г. - до 5 г.'}/>
      </ul>
    </nav>
  )
}

export default Navigation
