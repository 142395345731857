import React, { Component } from 'react'
import PhotoContextProvider from './context/PhotoContext'
import { HashRouter, Route, Switch } from 'react-router-dom'
import Header from './components/Header'
import Search from './components/Search'
import NotFound from './components/NotFound'

class App extends Component {
  render () {
    return (
      <PhotoContextProvider>
        <HashRouter basename="/bg">
          <div className="container">
            <Route
              render={() => (<Header/>)}
            />
            <Switch>
              <Route
                path="/"
                render={() => <Search/>}
              />
              <Route component={NotFound}/>
            </Switch>
          </div>
        </HashRouter>
      </PhotoContextProvider>
    )
  }
}

export default App
