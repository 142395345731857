import React, { useContext, useEffect } from 'react'
import { PhotoContext } from '../context/PhotoContext'
import Gallery from './Gallery'
import Loader from './Loader'

const Container = () => {
  const { images, loading, runSearch } = useContext(PhotoContext)
  useEffect(() => {
    runSearch()
    // eslint-disable-next-line
  }, [])
  
  return (
    <div className="photo-container">
      {loading ? <Loader/> : <Gallery data={images}/>}
    </div>
  )
}

export default Container
