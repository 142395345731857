import React from 'react'

const NoImages = () => (
  <div>
    <h3>{'Няма подходящи резултати'}</h3>
    <p>{'Моля, опитайте с други филтри'}</p>
  </div>
)

export default NoImages
