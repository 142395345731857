import React from 'react'
import NoImages from './NoImages'
import Image from './Image'
import { SRLWrapper } from 'simple-react-lightbox'

const Gallery = props => {
  const results = props.data
  let images
  let noImages
  if (results.length > 0) {
    images = results.map(image => {
      let id = image.id
      return <Image url={`/images/${image.path}`} key={id} id={id} alt={image.title}/>
      })
  } else {
    noImages = <NoImages/>
  }
  return (
    <div>
      <SRLWrapper>
        <ul>{images}</ul>
      </SRLWrapper>
      {noImages}
    </div>
  )
}

export default Gallery
