import React from 'react'

const Image = ({ id, url, title }) => (
  <li>
    <img src={url} alt={title}/>
    <div>{id}</div>
  </li>
)

export default Image
