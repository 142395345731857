import React from 'react'

const FilterItem = ({ handleClick, title, value, selectedValues }) => {
  return (
    <li>
      <button
        className={selectedValues.length > 0 && selectedValues.includes(value) ? 'active' : ''}
        onClick={() => handleClick(value)}
      >{title}</button>
    </li>
  )
}

export default FilterItem
